import React from "react";
import { forceRefresh } from "../lib/functions";

export function VersionLabel() {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        margin: "24px",
        color: "gray",
        cursor: "pointer",
      }}
      onClick={forceRefresh}
    >
      {process.env.REACT_APP_VERSION}
    </div>
  );
}
