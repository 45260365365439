import React, { useContext, useEffect, useState } from "react";
import { Header } from "./elements/header";
import { Button } from "./elements/button";
import { SubSegment } from "./elements/subSegment";
import { SegmentSpacer } from "./segmentSpacer";
import { Select } from "./elements/select";
import { Favorites } from "./favorites";
import { LF_FAVORITES, APIResources } from "../lib/definitions";
import * as localForage from "localforage";
import { doFetch } from "../lib/functions";
import { UserContext } from "../lib/contexts";
import { SelectOption } from "../lib/models";
import { Input } from "./elements/input";
import { TextArea } from "./elements/textarea";

interface CreateBetSegmentProps {
  onFinish(): void;
}

export function CreateBetSegment(props: CreateBetSegmentProps) {
  const [selectedUserOption, setSelectedUserOption] = useState<SelectOption>();
  const [selectedPrize, setSelectedPrize] = useState<"" | "Cola" | "Other">("");
  const [description, setDescription] = useState("");
  const [isCreatingBet, setIsCreatingBet] = useState(false);
  const [isDonating, setIsDonating] = useState(false);
  const [otherPrizeDescription, setOtherPrizeDescription] = useState("");
  const [favorites, setFavorites] = useState<SelectOption[]>([]);

  const { userInfo, allUsers } = useContext(UserContext);

  useEffect(() => {
    localForage.getItem(LF_FAVORITES).then((favorites) => {
      if (Array.isArray(favorites) && favorites.reduce((prev, curr) => prev && curr.value && curr.label, true)) {
        setFavorites(favorites);
      } else {
        setFavorites([]);
      }
    });
  }, [userInfo.email]);

  const getSelectPrizeButton = (text: "Cola" | "Other") => {
    return (
      <div
        onClick={() => setSelectedPrize(text)}
        className={
          "create-segment__select-mode-button" +
          (selectedPrize === text ? " create-segment__select-mode-button--selected" : "")
        }
      >
        {text}
      </div>
    );
  };

  const sendDisabled = !description || !selectedUserOption || !selectedPrize;
  const donateDisabled = !selectedUserOption || !selectedPrize;

  return (
    <div>
      <Header text="New bet" />
      <SubSegment title="Description">
        <TextArea
          className="create-segment__text-area"
          rows={5}
          value={description}
          onChange={setDescription}
          placeholder="Give a description..."
          autoFocus
        />
      </SubSegment>
      <SubSegment title="Prize">
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "24px" }}>
          {getSelectPrizeButton("Cola")}
          {getSelectPrizeButton("Other")}
        </div>
        {selectedPrize === "Other" && (
          <Input
            style={{ marginTop: "24px" }}
            placeholder="Specify prize..."
            value={otherPrizeDescription}
            onChange={setOtherPrizeDescription}
            autoFocus
          />
        )}
      </SubSegment>
      <SubSegment title="Opponent">
        <Favorites
          favorites={favorites}
          selected={selectedUserOption}
          onClick={(name) => setSelectedUserOption(name)}
        />
        <Select
          selected={selectedUserOption}
          options={(searchValue) => {
            if (!searchValue) {
              return [];
            } else {
              return allUsers
                .filter((user) => user.email !== userInfo.email)
                .map((u) => ({ label: u.fullName, value: u.email }))
                .filter(
                  (u) =>
                    u.value.toLowerCase().includes(searchValue.toLowerCase()) ||
                    u.label.toLowerCase().includes(searchValue.toLowerCase())
                )
                .sort((a, b) => a.label.localeCompare(b.label));
            }
          }}
          searchPlaceholder="Search all opponents..."
          onClick={(option) => setSelectedUserOption(option)}
        />
      </SubSegment>
      <div style={{ marginTop: "48px", display: "flex", justifyContent: "center" }}>
        <Button
          text="Donate"
          onClick={async () => {
            const prize = selectedPrize === "Cola" ? "1 cola" : otherPrizeDescription;
            if (window.confirm(`Are you sure you want to donate ${prize} to ${selectedUserOption?.label}?`)) {
              setIsDonating(true);
              doFetch("POST", APIResources.BetsDonate, props.onFinish, alert, () => setIsDonating(false), {
                opponent: selectedUserOption?.value,
                prize: prize,
                description: description,
              });
            }
          }}
          disabled={donateDisabled}
          loading={isDonating}
          style={{ marginRight: "24px", background: "#d1b000" }}
        />
        <Button
          text="Send"
          onClick={async () => {
            setIsCreatingBet(true);
            doFetch("POST", APIResources.Bets, props.onFinish, alert, () => setIsCreatingBet(false), {
              opponent: selectedUserOption?.value,
              prize: selectedPrize === "Cola" ? "1 cola" : otherPrizeDescription,
              description: description.trim(),
            });
          }}
          disabled={sendDisabled}
          loading={isCreatingBet}
        />
      </div>
      <div style={{ marginTop: "48px", display: "flex", justifyContent: "center" }}></div>
      <SegmentSpacer />
    </div>
  );
}
