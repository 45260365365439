import React from "react";
import { HomeOutlined, MailOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { NavigationBarItem } from "./navigationBarItem";
import { ContentMode } from "../lib/definitions";

interface NavigationBarProps {
  noOfMessages: number;
  contentMode: ContentMode;
  setContentMode(contentMode: ContentMode): void;
}

export function NavigationBar(props: NavigationBarProps) {
  const divider = <div style={{ height: "60%", width: "2px", background: "lightgray" }} />;

  return (
    <div className="navigation-bar">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr auto 1fr auto 1fr",
          gridAutoRows: "1fr",
          alignItems: "center",
          height: "100%",
        }}
      >
        <NavigationBarItem
          onClick={() => props.setContentMode("Status")}
          icon={
            <HomeOutlined
              className={
                "navigation-bar__icon" + (props.contentMode === "Status" ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => props.setContentMode("Create")}
          icon={
            <PlusOutlined
              className={
                "navigation-bar__icon" + (props.contentMode === "Create" ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => props.setContentMode("Messages")}
          icon={
            <MailOutlined
              className={
                "navigation-bar__icon" + (props.contentMode === "Messages" ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
          noOfMessages={props.noOfMessages}
        />

        {divider}
        <NavigationBarItem
          onClick={() => props.setContentMode("Profile")}
          icon={
            <UserOutlined
              className={
                "navigation-bar__icon" + (props.contentMode === "Profile" ? " navigation-bar__icon--highlight" : "")
              }
            />
          }
        />
      </div>
    </div>
  );
}
