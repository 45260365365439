import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../lib/contexts";
import { NEON_GREEN, APIResources, BetState } from "../lib/definitions";
import { doFetch, getTimestampString, typeToMessage } from "../lib/functions";
import { Bet, Message } from "../lib/models";
import { Button } from "./elements/button";

interface MessageCardProps {
  message: Message;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  refresh(): void;
}

export function MessageCard(props: MessageCardProps) {
  const [bet, setBet] = useState<Bet>();
  const [isFetching, setIsFetching] = useState(false);

  const { getUsername } = useContext(UserContext);
  const { setMessages } = props;

  const markMessageAsRead = useCallback(
    async (messageId: string) => {
      setMessages((messages) => messages.filter((msg) => msg._id !== messageId));
      await doFetch(
        "POST",
        `${APIResources.MessagesRead}/${messageId}`,
        () => {},
        (err) => alert(`Marking message as read: ${err}`)
      );
    },
    [setMessages]
  );

  useEffect(() => {
    setIsFetching(true);
    doFetch(
      "GET",
      `${APIResources.Bets}/${props.message.betId}`,
      setBet,
      async (err) => {
        alert(`Fetching bet information: ${err}`);
        await markMessageAsRead(props.message._id);
      },
      () => setIsFetching(false)
    );
  }, [props.message.betId, props.message._id, markMessageAsRead]);

  return (
    <div className="card">
      <div
        style={{
          color: "lightgray",
          display: "flex",
          alignItems: "center",
          marginBottom: "8px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {getTimestampString(props.message.timestamp)}
          <ClockCircleOutlined style={{ marginLeft: "8px" }} />
        </div>
        {bet?.state === BetState.Pending && <div style={{ color: "lightgrey" }}>Pending...</div>}
      </div>
      <div>{typeToMessage({ ...props.message, from: getUsername(props.message.from) })}</div>
      {isFetching ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "8px", color: "lightgrey" }}>
          <LoadingOutlined />
        </div>
      ) : (
        bet?.description && (
          <div style={{ fontStyle: "italic", margin: "16px 0", textAlign: "center" }}>{`"${bet?.description}"`}</div>
        )
      )}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Button
          text="Mark as read"
          size="small"
          onClick={() => markMessageAsRead(props.message._id)}
          style={{ background: "#a5a5a5" }}
        />
        <div
          style={{
            color: NEON_GREEN,
          }}
        >
          {bet?.prize}
        </div>
      </div>
    </div>
  );
}
