import React, { useEffect, useState } from "react";
import { CreateBetSegment } from "./createBetSegment";
import { StatusSegment } from "./statusSegment";
import { ProfileSegment } from "./profileSegment";
import { MessagesSegment } from "./messagesSegment";
import { Bet, Message, User, UserInfo } from "../lib/models";
import { InputtingContext, UserContext } from "../lib/contexts";
import { checkVersionRefresh, doFetch } from "../lib/functions";
import { APIResources, BACKEND_SOCKET, ContentMode } from "../lib/definitions";
import { NavigationBar } from "./navigationBar";

export let ws: WebSocket;

interface MainScreenProps {
  userInfo: UserInfo;
  logout(): void;
}

export function MainScreen(props: MainScreenProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [myBets, setMyBets] = useState<Bet[]>([]);
  const [contentMode, setContentMode] = useState<ContentMode>("Status");
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isInputting, setIsInputting] = useState(false);

  useEffect(() => {
    if (!props.userInfo.email) {
      return;
    }

    refresh();

    if (!ws) {
      ws = new WebSocket(BACKEND_SOCKET + `?email=${props.userInfo.email}`);
      ws.onmessage = refresh;
    }

    function refreshOnVisibilityChange() {
      if (document.visibilityState === "visible") {
        refresh();
        if (ws && !ws.OPEN) {
          ws.close();
          ws = new WebSocket(BACKEND_SOCKET + `?email=${props.userInfo.email}`);
          ws.onmessage = refresh;
        }
      }
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", refreshOnVisibilityChange);
    };
  }, [props.userInfo.email]);

  async function refresh() {
    let errorMessage;

    checkVersionRefresh();
    const a = doFetch("GET", APIResources.Messages, setMessages, (err) => (errorMessage = err));
    const b = doFetch("GET", APIResources.Bets, setMyBets, (err) => (errorMessage = err));
    const c = doFetch(
      "GET",
      "https://api.ckal.dk/users",
      setAllUsers,
      (err) => (errorMessage = err),
      undefined,
      undefined,
      true
    );

    await a;
    await b;
    await c;

    if (errorMessage) {
      alert(`Refreshing: ${errorMessage}`);
    }
  }

  function getUsername(email: string | undefined, onlyFirsName?: boolean) {
    const user = allUsers.find((u) => u.email === email);

    if (!user) {
      return email || "No driver name";
    }

    return onlyFirsName ? user.firstname : user.fullName;
  }

  return (
    <UserContext.Provider
      value={{ userInfo: props.userInfo, logout: props.logout, getUsername: getUsername, allUsers: allUsers }}
    >
      <InputtingContext.Provider value={{ isInputting, setIsInputting }}>
        <div
          style={{
            height: "100vh",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="main-screen__container">
            {contentMode === "Status" && <StatusSegment myBets={myBets} refresh={refresh} />}
            {contentMode === "Create" && (
              <CreateBetSegment
                onFinish={() => {
                  setContentMode("Status");
                  refresh();
                }}
              />
            )}
            {contentMode === "Messages" && (
              <MessagesSegment messages={messages} setMessages={setMessages} refresh={refresh} />
            )}
            {contentMode === "Profile" && <ProfileSegment />}
          </div>
          {!isInputting && (
            <NavigationBar contentMode={contentMode} setContentMode={setContentMode} noOfMessages={messages.length} />
          )}
        </div>
      </InputtingContext.Provider>
    </UserContext.Provider>
  );
}
