import React, { CSSProperties } from "react";
import { Header } from "./header";

interface SubSegmentProps {
  title: string;
  children?: React.ReactNode;
  headerStyles?: CSSProperties;
  icon?: React.ReactNode;
}

export function SubSegment(props: SubSegmentProps) {
  return (
    <div>
      <Header text={props.title} small style={props.headerStyles} icon={props.icon} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%" }}>{props.children}</div>
      </div>
    </div>
  );
}
