import React, { useContext, useEffect, useState } from "react";
import { GREEN, LF_FAVORITES } from "../lib/definitions";
import { Button } from "./elements/button";
import { Favorites } from "./favorites";
import { Header } from "./elements/header";
import { SegmentSpacer } from "./segmentSpacer";
import { Select } from "./elements/select";
import { SubSegment } from "./elements/subSegment";
import * as localForage from "localforage";
import { UserContext } from "../lib/contexts";
import { SelectOption } from "../lib/models";
import { forceRefresh } from "../lib/functions";

export function ProfileSegment() {
  const [favorites, setFavorites] = useState<SelectOption[]>([]);

  const { userInfo, logout, allUsers } = useContext(UserContext);

  useEffect(() => {
    localForage.getItem(LF_FAVORITES, (_, val) => updateFavorites(Array.isArray(val) ? val : []));
  }, []);

  function updateFavorites(favorites: SelectOption[]) {
    setFavorites(favorites);
    localForage.setItem(LF_FAVORITES, favorites);
  }

  return (
    <div>
      <Header text="Profile" />
      <SubSegment title="Account info">
        <div style={{ display: "grid", columnGap: "8px", gridTemplateColumns: "min-content auto", rowGap: "4px" }}>
          <div>Email:</div>
          <div style={{ color: GREEN }}> {userInfo.email}</div>
          <div>Name:</div>
          <div style={{ color: GREEN }}>{userInfo.firstname + " " + userInfo.lastname}</div>
        </div>
      </SubSegment>
      <SubSegment title="Favorites">
        <Favorites favorites={favorites} onClick={(name) => updateFavorites(favorites.filter((n) => n !== name))} />
        <Select
          options={(searchValue) => {
            if (!searchValue) {
              return [];
            } else {
              return allUsers
                .filter((user) => user.email !== userInfo.email)
                .map((u) => ({ label: u.fullName, value: u.email }))
                .filter(
                  (u) =>
                    u.value.toLowerCase().includes(searchValue.toLowerCase()) ||
                    u.label.toLowerCase().includes(searchValue.toLowerCase())
                )
                .sort((a, b) => a.label.localeCompare(b.label));
            }
          }}
          searchPlaceholder="Search opponent..."
          onClick={(name) => updateFavorites(favorites.concat(name).sort())}
          clearOnSelect
        />
      </SubSegment>
      <SubSegment title={`Version ${process.env.REACT_APP_VERSION}`}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button text="Update WebApp" onClick={forceRefresh} />
        </div>
      </SubSegment>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "48px" }}>
        <Button text="Sign out" onClick={logout} neutral />
      </div>
      <SegmentSpacer />
    </div>
  );
}
