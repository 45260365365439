import React, { useContext, useMemo } from "react";
import { GREEN } from "../lib/definitions";
import { Line, LineChart, ReferenceLine, ResponsiveContainer } from "recharts";
import { getChartData } from "../lib/functions";
import { Bet } from "../lib/models";
import { UserContext } from "../lib/contexts";

interface TendencyChartProps {
  bets: Bet[];
}

export function TendencyChart(props: TendencyChartProps) {
  const { userInfo } = useContext(UserContext);

  const data = useMemo(() => getChartData(props.bets, userInfo.email), [props.bets, userInfo.email]);

  return (
    <div style={{ width: "100%", height: "128px" }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <ReferenceLine y={0} stroke="gray" />
          <Line type="monotone" dataKey="y" stroke={GREEN} strokeWidth={2} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
