import { createContext } from "react";
import { User, UserInfo } from "./models";

export const UserContext = createContext<{
  userInfo: UserInfo;
  allUsers: User[];
  getUsername(email: string, onlyFirstName?: boolean): string;
  logout: () => void;
}>({
  userInfo: { email: "", firstname: "", lastname: "", nickname: "" },
  allUsers: [],
  getUsername: () => "",
  logout: () => {},
});

export const InputtingContext = createContext<{ isInputting: boolean; setIsInputting: (isInputting: boolean) => void }>(
  { isInputting: false, setIsInputting: () => {} }
);
