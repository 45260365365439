import React, { useEffect, useState } from "react";
import { FONT_SIZE_SMALL, GREEN } from "../../lib/definitions";
import { SelectOption } from "../../lib/models";
import { Input } from "./input";

interface SelectProps {
  options: SelectOption[] | ((searchValue: string) => SelectOption[]);
  selected?: SelectOption;
  searchPlaceholder?: string;
  clearOnSelect?: boolean;
  onClick(option: SelectOption): void;
}

export function Select(props: SelectProps) {
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);

  const { options, searchPlaceholder } = props;

  useEffect(() => {
    if (searchPlaceholder) {
      if (Array.isArray(options)) {
        setFilteredOptions(
          options.filter(
            (opt) =>
              opt.value.toLowerCase().includes(searchValue.toLowerCase()) ||
              opt.label.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setFilteredOptions(options(searchValue));
      }
    }
  }, [searchValue, options, searchPlaceholder]);

  return (
    <div>
      {props.searchPlaceholder && (
        <Input
          value={searchValue}
          onChange={setSearchValue}
          autoComplete="new-password"
          placeholder={props.searchPlaceholder}
          style={{ marginBottom: "16px" }}
        />
      )}
      <div
        style={{
          height: "180px",
          border: `1px solid ${GREEN}`,
          borderRadius: "32px",
          overflow: "auto",
          padding: "18px 32px",
          boxSizing: "border-box",
          display: "grid",
          rowGap: "12px",
          gridAutoRows: "min-content",
        }}
      >
        {(props.searchPlaceholder
          ? filteredOptions
          : Array.isArray(props.options)
          ? props.options
          : props.options(searchValue)
        ).map((option) => (
          <div
            key={option.value}
            style={{
              fontSize: FONT_SIZE_SMALL,
              color: option.value === props.selected?.value ? GREEN : undefined,
            }}
            onClick={() => {
              props.onClick(option);
              if (props.clearOnSelect) {
                setSearchValue("");
              }
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
