import React, { CSSProperties, useContext } from "react";
import { InputtingContext } from "../../lib/contexts";

interface TextAreaProps {
  value?: string;
  onChange?(value: string): void;
  autoFocus?: boolean;
  placeholder?: string;
  rows?: number;
  style?: CSSProperties;
  className?: string;
}

export function TextArea(props: TextAreaProps) {
  const { setIsInputting } = useContext(InputtingContext);

  return (
    <textarea
      className={"input " + props.className}
      rows={props.rows}
      value={props.value}
      onFocus={() => setIsInputting(true)}
      onBlur={() => setIsInputting(false)}
      onChange={(e) => props.onChange && props.onChange(e.target.value)}
      placeholder={props.placeholder}
      autoFocus
    />
  );
}
