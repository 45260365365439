import { LoadingOutlined } from "@ant-design/icons";
import React, { CSSProperties } from "react";

interface ButtonProps {
  text: string;
  loading?: boolean;
  width?: number;
  style?: CSSProperties;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  danger?: boolean;
  neutral?: boolean;
  onClick(): void;
}

export function Button(props: ButtonProps) {
  const sizeClass =
    props.size === "small" ? " button__small" : props.size === "large" ? " button__large" : " button__medium";

  return (
    <div
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
      style={{
        width: props.width,
        ...props.style,
        cursor: props.disabled ? undefined : "pointer",
      }}
      className={
        "button" + sizeClass + (props.disabled ? " button__disabled" : "") + (props.neutral ? " button__neutral" : "")
      }
    >
      {props.loading ? <LoadingOutlined /> : props.text}
    </div>
  );
}
