import React, { CSSProperties, KeyboardEvent, useContext } from "react";
import { InputtingContext } from "../../lib/contexts";

interface InputProps {
  value?: string;
  onChange?(value: string): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
  autoFocus?: boolean;
  autoComplete?: string;
  placeholder?: string;
  innerref?: React.RefObject<HTMLInputElement>;
  type?: string;
  style?: CSSProperties;
  className?: string;
}

export function Input(props: InputProps) {
  const { setIsInputting } = useContext(InputtingContext);

  return (
    <input
      className={"input " + props.className}
      style={props.style}
      placeholder={props.placeholder}
      value={props.value}
      onKeyDown={props.onKeyDown}
      onFocus={() => setIsInputting(true)}
      onBlur={() => setIsInputting(false)}
      ref={props.innerref}
      type={props.type}
      onChange={(e) => props.onChange && props.onChange(e.target.value)}
      autoFocus={props.autoFocus}
      autoComplete={props.autoComplete}
    />
  );
}
