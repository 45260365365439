import { ArrowDownOutlined, ArrowUpOutlined, TrophyOutlined } from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { UserContext } from "../lib/contexts";
import { FONT_SIZE_MEDIUM, FONT_SIZE_SMALL, GREEN, RED } from "../lib/definitions";
import { getOtherPerson, isBetOver, isBetWon } from "../lib/functions";
import { Bet } from "../lib/models";
import { Header } from "./elements/header";

interface ScoreSubSegmentProps {
  bets: Bet[];
}

export function ScoreSubSegment(props: ScoreSubSegmentProps) {
  const { userInfo, getUsername } = useContext(UserContext);

  const scoreStats = useMemo(() => {
    const settledBets = props.bets.filter((bet) => isBetOver(bet)).sort((a, b) => a.timestamp - b.timestamp);

    const scoreMap: { [k in string]: { wonAgainst: number; lostAgainst: number } } = {};

    let won = 0;
    let lost = 0;

    let longestWinStreak = 0;
    let longestLossStreak = 0;

    let currentWinStreak = 0;
    let currentLossStreak = 0;

    settledBets.forEach((bet) => {
      const opp = getOtherPerson(bet, userInfo.email);
      const currentScore = scoreMap[opp] || { wonAgainst: 0, lostAgainst: 0 };
      if (isBetWon(bet, userInfo.email)) {
        won++;
        currentWinStreak++;
        if (currentWinStreak > longestWinStreak) {
          longestWinStreak = currentWinStreak;
        }
        currentLossStreak = 0;
        scoreMap[opp] = { ...currentScore, wonAgainst: currentScore.wonAgainst + 1 };
      } else {
        lost++;
        currentLossStreak++;
        if (currentLossStreak > longestLossStreak) {
          longestLossStreak = currentLossStreak;
        }
        currentWinStreak = 0;
        scoreMap[opp] = { ...currentScore, lostAgainst: currentScore.lostAgainst + 1 };
      }
    });

    return { scoreMap, won, lost, longestWinStreak, longestLossStreak };
  }, [props.bets, userInfo.email]);

  return (
    <div>
      <Header text="Score" icon={<TrophyOutlined />} />
      <div style={{ display: "flex", justifyContent: "space-between", fontSize: FONT_SIZE_MEDIUM }}>
        <div>{`${Math.round((100 * scoreStats.won) / Math.max(scoreStats.won + scoreStats.lost, 1))}%`}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ color: GREEN, marginRight: "4px" }}>{scoreStats.longestWinStreak}</div>
          <ArrowUpOutlined style={{ fontSize: FONT_SIZE_SMALL }} />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ color: RED, marginRight: "4px" }}>{scoreStats.longestLossStreak}</div>
          <ArrowDownOutlined style={{ fontSize: FONT_SIZE_SMALL }} />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ color: GREEN }}>{scoreStats.won}</div>
          <div style={{ margin: "-2px 8px 0 8px" }}>|</div>
          <div style={{ color: RED }}>{scoreStats.lost}</div>
        </div>
      </div>
      <div style={{ borderTop: "1px solid gray", margin: "16px" }} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto min-content min-content min-content",
          rowGap: "8px",
          fontSize: "20px",
        }}
      >
        {Object.entries(scoreStats.scoreMap)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([opponent, score]) => (
            <React.Fragment key={opponent}>
              <div>{getUsername(opponent)}</div>
              <div style={{ color: GREEN, textAlign: "end" }}>{score.wonAgainst}</div>
              <div style={{ margin: "-2px 8px 0 8px", textAlign: "end" }}>|</div>
              <div style={{ color: RED, textAlign: "end" }}>{score.lostAgainst}</div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}
