export const DOMAIN = "ckal.dk";
export const API_ENDPOINT = `https://api.${DOMAIN}/yomi/`;

export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

export const BACKEND_SOCKET = `wss://${DOMAIN}`;

export const BACKGROUND_GRAY = "#252525";
export const GREEN = "#3ac358";
export const NEON_GREEN = "#00ff00";
export const BLUE = "#3aa2c3";
export const RED = "#ff3b3b";

export const FONT_SIZE_LARGE = "36px";
export const FONT_SIZE_MEDIUM = "24px";
export const FONT_SIZE_SMALL = "18px";

export const TABLE_NAME_USERS = "Users";
export const TABLE_NAME_MESSAGES = "Messages";
export const TABLE_NAME_BETS = "Bets";

export const LF_CREDENTIALS = "creds";
export const LF_FAVORITES = "favorites";

export enum MessageType {
  BetCreated = "BET_CREATED",
  BetDeclined = "BET_DECLINED",
  BetAccepted = "BET_ACCEPTED",
  BetWon = "BET_WON",
  BetUsed = "BET_USED",
  Donation = "DONATION",
}

export enum BetState {
  Declined = "DECLINED",
  Pending = "PENDING",
  Active = "ACTIVE",
  ChallengerWon = "CHALLENGER_WON",
  ChallengerLost = "CHALLENGER_LOST",
  Donation = "DONATION",
}

export enum APIResources {
  Bets = "bets",
  BetsDonate = "bets/donate",
  BetsLose = "bets/lose",
  BetsAccept = "bets/accept",
  BetsDecline = "bets/decline",
  BetsUse = "bets/use",
  Messages = "messages",
  MessagesRead = "messages/read",
  Usernames = "usernames",
}

export type ContentMode = "Status" | "Create" | "Messages" | "Profile";
