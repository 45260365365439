import { ClockCircleOutlined } from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { UserContext } from "../lib/contexts";
import { BetState, NEON_GREEN, APIResources } from "../lib/definitions";
import { doFetch, getOtherPerson, getTimestampString } from "../lib/functions";
import { Bet } from "../lib/models";
import { Button } from "./elements/button";

interface BetCardProps {
  bet: Bet;
  refresh(): void;
}

export function BetCard(props: BetCardProps) {
  const { getUsername, userInfo } = useContext(UserContext);

  const waitingForAction = useMemo(
    () => props.bet.state === BetState.Pending && props.bet.opponent === userInfo.email,
    [props.bet.state, props.bet.opponent, userInfo.email]
  );

  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "lightgray",
          marginBottom: "8px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {getTimestampString(props.bet.timestamp)}
          <ClockCircleOutlined style={{ marginLeft: "8px" }} />
        </div>
        <div style={{ color: props.bet.state === BetState.Pending ? "lightgrey" : NEON_GREEN }}>
          {props.bet.state === BetState.Pending ? "Pending..." : "Active"}
        </div>
      </div>
      {waitingForAction ? (
        <div>{getUsername(getOtherPerson(props.bet, userInfo.email)) + " would like to make a bet with you:"}</div>
      ) : (
        <div style={{ display: "flex", color: "lightgray" }}>
          Opponent:
          <div style={{ color: "white", marginLeft: "8px" }}>
            {getUsername(getOtherPerson(props.bet, userInfo.email))}
          </div>
        </div>
      )}
      <div style={{ margin: "16px 0", textAlign: "center", color: "lightgray" }}>
        {getUsername(props.bet.challenger, true) + ":"}
        <i style={{ color: "white", marginLeft: "8px" }}>"{props.bet.description}"</i>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", marginTop: "8px" }}>
          {waitingForAction && (
            <>
              <Button
                text="Decline"
                onClick={() => {
                  if (window.confirm("Decline bet?")) {
                    doFetch("POST", `${APIResources.BetsDecline}/${props.bet._id}`, props.refresh, (err) =>
                      alert(`Declining bet: ${err}`)
                    );
                  }
                }}
                size="small"
                style={{ marginRight: "8px", background: "#a5a5a5" }}
              />
              <Button
                text="Accept"
                onClick={() => {
                  if (window.confirm("Accept bet?")) {
                    doFetch("POST", `${APIResources.BetsAccept}/${props.bet._id}`, props.refresh, (err) =>
                      alert(`Accepting bet: ${err}`)
                    );
                  }
                }}
                size="small"
              />
            </>
          )}
          {props.bet.state === BetState.Active && (
            <Button
              text="Acknowledge loss"
              onClick={() => {
                if (window.confirm("Acknowledge lost bet?")) {
                  doFetch("POST", APIResources.BetsLose + "/" + props.bet._id, props.refresh, (err) =>
                    alert(`Acknowledging lost bet: ${err}`)
                  );
                }
              }}
              size="small"
              style={{ background: "#a5a5a5" }}
            />
          )}
        </div>
        <div
          style={{
            color: NEON_GREEN,
          }}
        >
          {props.bet.prize}
        </div>
      </div>
    </div>
  );
}
