import { MailOutlined } from "@ant-design/icons";
import React from "react";
import { Message } from "../lib/models";
import { Header } from "./elements/header";
import { MessageCard } from "./messageCard";
import { SegmentSpacer } from "./segmentSpacer";

interface MessagesSegmentProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  refresh(): void;
}

export function MessagesSegment(props: MessagesSegmentProps) {
  return (
    <div>
      <Header text="Messages" icon={<MailOutlined />} />
      {props.messages.length === 0 ? (
        <Header text="You have no new messages..." small center style={{ marginTop: "48px" }} />
      ) : (
        <div style={{ display: "grid", gridAutoRows: "auto", rowGap: "24px" }}>
          {props.messages.map((message) => (
            <MessageCard
              key={message.timestamp}
              message={message}
              setMessages={props.setMessages}
              refresh={props.refresh}
            />
          ))}
        </div>
      )}
      <SegmentSpacer />
    </div>
  );
}
