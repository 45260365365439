import React, { useContext } from "react";
import { UserContext } from "../lib/contexts";
import { NEON_GREEN, APIResources } from "../lib/definitions";
import { doFetch, getOtherPerson, isBetWon } from "../lib/functions";
import { Bet } from "../lib/models";
import { Button } from "./elements/button";

interface StatusCardProps {
  bet: Bet;
  refresh(): void;
}

export function StatusCard(props: StatusCardProps) {
  const { userInfo, getUsername } = useContext(UserContext);

  const betWon = isBetWon(props.bet, userInfo.email);

  return (
    <div className="card" style={{ borderRadius: "8px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ whiteSpace: "nowrap", marginRight: "8px" }}>
          {getUsername(getOtherPerson(props.bet, userInfo.email))}
        </div>
        <div
          style={{
            color: isBetWon(props.bet, userInfo.email) ? NEON_GREEN : "lightgrey",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {props.bet.prize}
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "8px", color: "lightgray" }}>
        {`${getUsername(props.bet.challenger)}:`}
        <i style={{ marginLeft: "8px" }}>"{props.bet.description}"</i>
      </div>
      {betWon && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
          <Button
            text="Cash in bet prize"
            size="small"
            onClick={() => {
              if (window.confirm("Cash in bet prize?")) {
                doFetch("POST", `${APIResources.BetsUse}/${props.bet._id}`, props.refresh, (err) =>
                  alert(`Cashing in bet prize: ${err}`)
                );
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
