import React from "react";
import { GREEN } from "../lib/definitions";

export function WelcomeScreen() {
  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <div
        style={{
          position: "absolute",
          fontSize: "72px",
          top: "45%",
          left: "50%",
          fontWeight: "bold",
          color: GREEN,
          transform: "translate(-50%,-50%)",
        }}
      >
        Yomi
      </div>
    </div>
  );
}
