import React, { useEffect, useState } from "react";
import { LoginScreen } from "./components/loginScreen";
import { MainScreen } from "./components/mainScreen";
import { UserInfo } from "./lib/models";
import { clearCredentials, getUserInfo } from "./lib/credentialsHandler";
import { checkVersionRefresh } from "./lib/functions";
import { WelcomeScreen } from "./components/welcomeScreen";


export function App() {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isFirstLoginTry, setIsFirstLoginTry] = useState(true);

  useEffect(() => {
    updateUserInfo();
  }, []);

  async function updateUserInfo() {
    setIsGettingUserInfo(true);
    try {
      setUserInfo(await getUserInfo());
    } catch {
      checkVersionRefresh();
    }
    setIsGettingUserInfo(false);
  }

  return (
    <div>
      {isGettingUserInfo && isFirstLoginTry && !isWorking ? (
        <WelcomeScreen />
      ) : !userInfo ? (
        <LoginScreen onLogin={updateUserInfo} isWorking={isWorking} setIsWorking={setIsWorking} />
      ) : (
        <MainScreen
          userInfo={userInfo}
          logout={async () => {
            await clearCredentials();
            setIsFirstLoginTry(false);
            setUserInfo(undefined);
          }}
        />
      )}
      <div style={{ height: "200px" }} />
    </div>
  );
}
