import { StarFilled } from "@ant-design/icons";
import React from "react";
import { FONT_SIZE_SMALL, GREEN } from "../lib/definitions";
import { SelectOption } from "../lib/models";

interface FavoritesProps {
  selected?: SelectOption;
  favorites: SelectOption[];
  onClick(favorite: SelectOption): void;
}

export function Favorites(props: FavoritesProps) {
  return (
    <div>
      {props.favorites.length === 0 ? (
        <div style={{ margin: "0 0 18px 18px" }}>You have no favorites...</div>
      ) : (
        props.favorites.map((option) => (
          <div
            key={option.value}
            style={{
              padding: "0 18px",
              fontSize: FONT_SIZE_SMALL,
              marginBottom: "16px",
              color: props.selected?.value === option.value ? GREEN : undefined,
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => props.onClick(option)}
          >
            <StarFilled style={{ marginRight: "8px", color: "yellow" }} />
            <div style={{ marginTop: "-1px" }}>{option.label}</div>
          </div>
        ))
      )}
    </div>
  );
}
