import React, { CSSProperties } from "react";
import { GREEN, FONT_SIZE_LARGE, FONT_SIZE_MEDIUM } from "../../lib/definitions";

interface HeaderProps {
  text: string;
  icon?: any;
  small?: boolean;
  center?: boolean;
  style?: CSSProperties;
}

export function Header(props: HeaderProps) {
  return (
    <h1
      style={{
        color: props.small ? "gray" : GREEN,
        margin: props.center ? undefined : "36px 0 16px 8px",
        fontSize: props.small ? FONT_SIZE_MEDIUM : FONT_SIZE_LARGE,
        display: "flex",
        alignItems: "center",
        justifyContent: props.center ? "center" : "space-between",
        textAlign: props.center ? "center" : undefined,
        ...props.style,
      }}
    >
      {props.text}
      {props.icon && <div>{props.icon}</div>}
    </h1>
  );
}
