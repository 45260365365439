import { StockOutlined } from "@ant-design/icons";
import React, { useContext, useMemo } from "react";
import { UserContext } from "../lib/contexts";
import { BetState } from "../lib/definitions";
import { getOtherPerson, isBetOver, isBetWon } from "../lib/functions";
import { Bet } from "../lib/models";
import { BetCard } from "./betCard";
import { Header } from "./elements/header";
import { ScoreSubSegment } from "./scoreSubSegment";
import { SegmentSpacer } from "./segmentSpacer";
import { StatusSubSegment } from "./statusSubSegment";
import { TendencyChart } from "./tendencyGraph";
import { FaHandshake } from "react-icons/fa";

interface StatusSegmentProps {
  myBets: Bet[];
  refresh(): void;
}

export function StatusSegment(props: StatusSegmentProps) {
  const { userInfo, getUsername } = useContext(UserContext);

  const bets = useMemo(
    () => props.myBets.filter((bet) => [BetState.Pending, BetState.Active].includes(bet.state)),
    [props.myBets]
  );

  const unusedBets = useMemo(
    () =>
      props.myBets
        .filter((bet) => isBetOver(bet) && !bet.used)
        .sort((a, b) => {
          const otherPersonA = getUsername(getOtherPerson(a, userInfo.email));
          const otherPersonB = getUsername(getOtherPerson(b, userInfo.email));
          if (otherPersonA === otherPersonB) {
            return (isBetWon(a, userInfo.email) ? 1 : 0) - (isBetWon(b, userInfo.email) ? 1 : 0);
          } else {
            return otherPersonA.localeCompare(otherPersonB);
          }
        }),
    [props.myBets, userInfo.email, getUsername]
  );

  return (
    <div>
      <Header text="Bets" icon={<FaHandshake style={{ marginBottom: "-6px" }} />} />
      {bets.length === 0 ? (
        <Header text="You have no active bets..." small center />
      ) : (
        <div style={{ display: "grid", gridAutoRows: "auto", rowGap: "24px" }}>
          {bets.map((bet) => (
            <BetCard key={bet.timestamp} bet={bet} refresh={props.refresh} />
          ))}
        </div>
      )}
      <Header text="Tendency" icon={<StockOutlined />} />
      {props.myBets.filter((bet) => isBetOver(bet)).length === 0 ? (
        <Header text="You have not participated in any bet yet..." small center />
      ) : (
        <TendencyChart bets={props.myBets} />
      )}
      <ScoreSubSegment bets={props.myBets} />
      <StatusSubSegment unusedBets={unusedBets} refresh={props.refresh} />
      <SegmentSpacer />
    </div>
  );
}
