import React, { useEffect, useRef, useState } from "react";
import { login } from "../lib/credentialsHandler";
import { DOMAIN, FONT_SIZE_SMALL, GREEN } from "../lib/definitions";
import { Button } from "./elements/button";
import { Input } from "./elements/input";
import { VersionLabel } from "./versionLabel";

interface LoginScreenProps {
  isWorking: boolean;
  setIsWorking(isWorking: boolean): void;
  onLogin(): void;
}

export function LoginScreen(props: LoginScreenProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isMounted = useRef(true);

  useEffect(() => {
    setEmail(new URLSearchParams(window.location.search).get("email") || "");
    return () => {
      isMounted.current = false;
    };
  }, []);

  const passwordInputRef = useRef<HTMLInputElement>(null);

  async function tryLogin() {
    try {
      await login(email, password);
      props.onLogin();
    } catch (error) {
      alert((error as Error).message);
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "600px" }}>
        <div
          style={{
            fontSize: "48px",
            marginTop: "48px",
            fontWeight: "bold",
            color: GREEN,
            marginBottom: "128px",
          }}
        >
          Yomi
        </div>

        <>
          <div style={{ width: "60%" }}>
            <div>
              <Input
                placeholder="Email..."
                style={{ marginBottom: "16px" }}
                value={email}
                onChange={setEmail}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    passwordInputRef.current?.select();
                  }
                }}
                autoComplete="new-password"
              />
              <Input
                placeholder="Password..."
                style={{ marginBottom: "16px" }}
                value={password}
                onChange={setPassword}
                innerref={passwordInputRef}
                type="password"
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    tryLogin();
                  }
                }}
                autoComplete="new-password"
              />
            </div>
          </div>
          <Button
            onClick={async () => {
              try {
                props.setIsWorking(true);

                await tryLogin();
              } catch (error) {
                alert((error as Error).message);
              } finally {
                props.setIsWorking(false);
              }
            }}
            text="Login"
            loading={props.isWorking}
            disabled={!email || !password}
            style={{
              margin: "8px 0 36px 0",
              width: "60px",
            }}
          />
          <h3
            style={{ fontSize: FONT_SIZE_SMALL, margin: 0 }}
            onClick={() => (window.location.href = `https://${DOMAIN}/sign-up?redirect=${window.location.href}`)}
          >
            Sign up
          </h3>
          <div
            style={{ fontSize: FONT_SIZE_SMALL, marginTop: "32px" }}
            onClick={() =>
              (window.location.href = `https://${DOMAIN}/forgot-password?redirect=${window.location.href}`)
            }
          >
            Forgot password?
          </div>
        </>

        <VersionLabel />
      </div>
    </div>
  );
}
