import React, { useCallback, useContext, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Header } from "./elements/header";
import { SubSegment } from "./elements/subSegment";
import { StatusCard } from "./statusCard";
import { useMemo } from "react";
import { getOtherPerson, isBetWon } from "../lib/functions";
import { Button } from "./elements/button";
import { Bet } from "../lib/models";
import { UserContext } from "../lib/contexts";

interface StatusSubSegmentProps {
  unusedBets: Bet[];
  refresh(): void;
}

export function StatusSubSegment(props: StatusSubSegmentProps) {
  const [showWonBets, setShowWonBets] = useState(true);
  const [showLostBets, setShowLostBets] = useState(true);

  const { userInfo, getUsername } = useContext(UserContext);

  const sorter = useCallback(
    (a: Bet, b: Bet, usern: string): number => {
      const oppA = getUsername(getOtherPerson(a, usern));
      const oppB = getUsername(getOtherPerson(b, usern));

      if (oppA === oppB) {
        return a.prize.localeCompare(b.prize);
      } else {
        return oppA.localeCompare(oppB);
      }
    },
    [getUsername]
  );

  const wonBets = useMemo(
    () => props.unusedBets.filter((bet) => isBetWon(bet, userInfo.email)).sort((a, b) => sorter(a, b, userInfo.email)),
    [props.unusedBets, userInfo.email, sorter]
  );

  const lostBets = useMemo(
    () => props.unusedBets.filter((bet) => !isBetWon(bet, userInfo.email)).sort((a, b) => sorter(a, b, userInfo.email)),
    [props.unusedBets, userInfo.email, sorter]
  );

  return (
    <div>
      <Header text="Status" icon={<MenuOutlined />} style={{ marginBottom: 0 }} />
      {wonBets.length === 0 && lostBets.length === 0 && <Header text="Nothing to show..." small center />}
      {wonBets.length > 0 && (
        <SubSegment
          title={`Won (${wonBets.length})`}
          headerStyles={{ marginTop: "32px" }}
          icon={
            <Button
              text={showWonBets ? "Hide" : "Show"}
              onClick={() => setShowWonBets(!showWonBets)}
              size="small"
              neutral
            />
          }
        >
          {showWonBets ? (
            <div style={{ display: "grid", gridAutoRows: "auto", rowGap: "12px" }}>
              {wonBets.map((bet) => (
                <StatusCard key={bet.timestamp} bet={bet} refresh={props.refresh} />
              ))}
            </div>
          ) : (
            <div />
          )}
        </SubSegment>
      )}
      {lostBets.length > 0 && (
        <SubSegment
          title={`Lost (${lostBets.length})`}
          headerStyles={{ marginTop: showWonBets && wonBets.length > 0 ? "30px" : "16px" }}
          icon={
            <Button
              text={showLostBets ? "Hide" : "Show"}
              onClick={() => setShowLostBets(!showLostBets)}
              size="small"
              neutral
            />
          }
        >
          {showLostBets ? (
            <div style={{ display: "grid", gridAutoRows: "auto", rowGap: "12px" }}>
              {lostBets.map((bet) => (
                <StatusCard key={bet.timestamp} bet={bet} refresh={props.refresh} />
              ))}
            </div>
          ) : (
            <div />
          )}
        </SubSegment>
      )}
    </div>
  );
}
