import React from "react";

interface NavigationBarItemProps {
  icon: any;
  onClick(): void;
  noOfMessages?: number;
}

export function NavigationBarItem(props: NavigationBarItemProps) {
  return (
    <div
      style={{
        outline: "none",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <div style={{ position: "relative" }}>
        {props.icon}
        {(props.noOfMessages || 0) > 0 && (
          <div
            style={{
              height: "16px",
              width: "16px",
              background: "#ff4d4f",
              borderRadius: "10px",
              boxShadow: "0 0 0 1px white",
              top: "0",
              right: "0",
              position: "absolute",
              marginTop: "-5px",
              marginRight: "-10px",
              color: "white",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            <div style={{ margin: "0 0 0 1px" }}>{props.noOfMessages}</div>
          </div>
        )}
      </div>
    </div>
  );
}
