import { CredentialsError, getAccessToken } from "./credentialsHandler";
import { API_ENDPOINT, BetState, DOMAIN, MessageType } from "./definitions";
import { Bet, Message } from "./models";
import * as localForage from "localforage";

export async function doFetch(
  httpMethod: "GET" | "POST" | "PUT" | "DELETE",
  path: string,
  onOK: (json: any) => void,
  onNotOK: (json: any) => void,
  finallyCallback?: () => void,
  body?: any,
  customUrl?: boolean,
  noToken?: boolean
) {
  try {
    const response = await fetch(customUrl ? path : `${API_ENDPOINT}${path}`, {
      headers: await getHeaders(noToken),
      method: httpMethod,
      body: body ? JSON.stringify(body) : undefined,
    });
    if (response.ok) {
      try {
        onOK(await response.json());
      } catch {
        onOK(`${response.status} ${response.statusText}`);
      }
    } else {
      try {
        onNotOK(await response.json());
      } catch (error) {
        onNotOK(response.statusText);
      }
    }
  } catch (error) {
    console.log(error);
    if (error instanceof CredentialsError) {
      onNotOK(error.message);
    } else {
      onNotOK("An error occured");
    }
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
}

export async function getHeaders(noToken?: boolean) {
  const headers = new Headers();

  headers.append("Content-Type", "application/json");
  if (!noToken) {
    headers.append("Authorization", await getAccessToken());
  }

  return headers;
}

export function getTimestampString(timestamp: number) {
  const date = new Date(timestamp);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][date.getMonth()];

  const dayPostFix =
    4 <= date.getDate() && date.getDate() <= 20
      ? "th"
      : date.getDate() % 10 === 1
      ? "st"
      : date.getDate() % 10 === 2
      ? "nd"
      : date.getDate() % 10 === 3
      ? "rd"
      : "th";

  return month + " " + date.getDate() + dayPostFix + ", " + date.toLocaleTimeString().replace(/\./g, ":").slice(0, -3);
}

export function typeToMessage(message: Message) {
  if (message.type === MessageType.BetCreated) {
    return `${message.from} would like to make a bet with you`;
  } else if (message.type === MessageType.BetAccepted) {
    return `${message.from} accepted the bet`;
  } else if (message.type === MessageType.BetDeclined) {
    return `${message.from} declined the bet`;
  } else if (message.type === MessageType.BetWon) {
    return `You won the bet against ${message.from}`;
  } else if (message.type === MessageType.BetUsed) {
    return `${message.from} cashed in a bet`;
  } else if (message.type === MessageType.Donation) {
    return `${message.from} made a donation`;
  }
  return "";
}

export function getOtherPerson(bet: Bet, username: string) {
  return bet.challenger === username ? bet.opponent : bet.challenger;
}

export function isBetOver(bet: Bet) {
  return [BetState.ChallengerWon, BetState.ChallengerLost, BetState.Donation].includes(bet.state);
}

export function isBetWon(bet: Bet, username: string) {
  return (
    (bet.state === BetState.ChallengerWon ? bet.challenger === username : bet.opponent === username) ||
    (bet.state === BetState.Donation && bet.opponent === username)
  );
}

export function getChartData(bets: Bet[], username: string) {
  const finishedBets = bets.filter((bet) => isBetOver(bet)).sort((a, b) => a.timestamp - b.timestamp);

  let balance = 0;

  const data = finishedBets.map((bet) => {
    balance = balance + (isBetWon(bet, username) ? 1 : -1);

    return { x: bet.timestamp, y: balance };
  });

  data.unshift({ x: 1604100000000, y: 0 });

  return data;
}

export async function forceRefresh() {
  // const reg = await navigator.serviceWorker.getRegistration();
  // if (reg) {
  //   await reg.unregister();
  // }
  window.location.reload();
}

export function checkVersionRefresh() {
  doFetch(
    "GET",
    `https://api.${DOMAIN}/version/yomi`,
    (response) => {
      localForage.getItem("refreshTarget", (_err, refreshTarget) => {
        if (refreshTarget !== response) {
          if (response !== process.env.REACT_APP_VERSION) {
            if (window.confirm("A newer version of the app is available. Update now?")) {
              localForage.setItem("refreshTarget", response).then(forceRefresh);
            }
          }
        } else {
          localForage.setItem("refreshTarget", "");
        }
      });
    },
    (err) => alert(`Fetching newest version: ${err}`),
    undefined,
    undefined,
    true,
    true
  );
}
